import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import NewsletterForm from '../components/newsletterform'

const Footer = () => {
  return (
		<div className="uni-container footer">
			{/* <div className="uni-footer-col">
				<p className="uni-footer-title">About Us</p>
				<a href="/about" className="uni-footer-link">Who we are</a>
				<a href="/press" className="uni-footer-link">Press</a>
			</div> */}
			<div className="uni-footer-col support-col">
				<p className="uni-footer-title">Support</p>
				<Link to="/contact-us" className="uni-footer-link">Contact us</Link>
				<Link to="/privacy-policy" className="uni-footer-link">Privacy</Link>
				<Link to="/terms-of-service" className="uni-footer-link">Terms</Link>
			</div>
			<div className="uni-footer-col about-col">
				<p className="uni-footer-title">Join Sunset</p>
				<Link to="/about" className="uni-footer-link">Who we are</Link>
				<OutboundLink href="https://customer.sunset.health/" target="_blank" className="uni-footer-link">
					Sign up for Sunset
				</OutboundLink>
				<Link to="/employers" className="uni-footer-link">Become a Sunset provider</Link>
			</div>
			<div className="uni-footer-col social-col">
				<div className="uni-footer-social-wrap">
					<OutboundLink href="https://www.linkedin.com/company/sunset-health/" target="_blank" className="uni-social-icon-link">
						<img src={require('../../content/assets/linkedin_noborder.png')} alt="Sunset on LinkedIn" className="uni-social-icon" />
					</OutboundLink>
					<OutboundLink href="https://twitter.com/sunsethealth" target="_blank" className="uni-social-icon-link">
						<img src={require('../../content/assets/twitter_noborder.png')} alt="Sunset on Twitter" className="uni-social-icon" />
					</OutboundLink>
					<OutboundLink href="https://www.instagram.com/fightburnout/" target="_blank" className="uni-social-icon-link">
						<img src={require('../../content/assets/instagram.png')} alt="Sunset on Instagram" className="uni-social-icon" />
					</OutboundLink>
					<OutboundLink href="https://medium.com/sunsethealth" target="_blank" className="uni-social-icon-link">
						<img src={require('../../content/assets/medium.png')} alt="Sunset on Medium" className="uni-social-icon" />
					</OutboundLink>
				</div>
				<div className="uni-footer-apps">
					<OutboundLink href="https://apps.apple.com/us/app/sunset-health/id1490337966?itsct=apps_box&amp;itscg=30200" 
						className="ios-app-container">
							<img 
								src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1576108800&h=f58936e21f0e752c8202144c8bced4b7" 
								alt="Download on the App Store" 
								className='uni-footer-ios-app'
							/>
					</OutboundLink>
					<OutboundLink href='https://play.google.com/store/apps/details?id=com.sunsetfrontend&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
							style={{
								display: 'inline-block', 
								overflow: 'hidden', 
							}}
					>
						<img 
							alt='Get it on Google Play' 
							src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
							className="uni-footer-android-app"
							/>
					</OutboundLink>
				</div>
			</div>
			<div className="uni-footer-col newsletter-col">
				<NewsletterForm />
			</div>
		</div>
  )
}

export default Footer
