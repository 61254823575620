import React, {useState} from "react"
import { 
  Formik,
  Form, 
  Field,
  ErrorMessage 
} from 'formik';
import * as yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp'

let validation = yup.object().shape({
    email: yup
            .string()
            .email('Not a valid email')
            .required('Email is missing'),
  });

const NewsletterForm = () => {
    const [submitError, setSubmitError] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [submissionMessage, setSubmissionMessage] = useState('');

    return (
        <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={validation}
        onSubmit={(values) => {
          setSubmitSuccess(false);
          setSubmitError(false);
          setSubmissionMessage('');
          addToMailchimp(values.email) // listFields are optional if you are only capturing the email address.
          .then(data => {
            // I recommend setting data to React state
            // but you can do whatever you want (including ignoring this `then()` altogether)
            if (data.result === 'error') {
              setSubmitError(true);
              setSubmissionMessage(data.msg)
            } else {
              setSubmitSuccess(true);
              setSubmissionMessage(data.msg)
              process.env.NODE_ENV === 'production' && typeof window !== "undefined" && window.gtag("event", "subscribe", {   
                'event_category': "Subscribe",
                'event_label': "Newsletter subscription",
                'value': 3 
              })
            }
          })
        }}
      >
        <Form className="form">
            <div className="uni-footer-newsletter-wrap">
                <p className="uni-footer-title newsletter-title">Sunset Newsletter</p>
                <p className="uni-footer-title newsletter-subtitle">Get weekly wellness updates straight to your inbox</p>
                <p className="uni-footer-title newsletter-description">Sign up for Sunset Weekly - Stress, Burnout, and Mental Health news and tips in 5 minutes or less, for free.</p>
                {
                  !submitSuccess ? (<div className="uni-footer-newsletter-input">
                          <Field
                              className='uni-footer-signup-input'
                              type='text'
                              placeholder={'Enter your email'}
                              name='email'
                          />
                      <button
                          className='uni-footer-signup-btn'
                          type='submit'
                      >
                          Subscribe
                      </button>
                  </div>)  : (
                      <div className="success-description">
                          <p>{submissionMessage}</p>
                      </div>
                  ) 
              }
              <ErrorMessage className='error' component="div" name='email' />
              {
                    submitError && (
                    <div className='error-container'>
                        <p className='submit-error' dangerouslySetInnerHTML={{ __html: submissionMessage }}></p>
                    </div>
                    )
                }
			</div>
        </Form>
      </Formik>
    )
}

export default NewsletterForm;