import React, {useState} from "react"
import Image from "gatsby-image"
import HelpForm from "../components/helpform"
import { Link } from "gatsby"

const PopupForm = ({isPopupOpen, onClickPopup}) => {

	let display = isPopupOpen ? {display: 'block', opacity: 1} : {display: 'none', opacity: 0};

	let togglePopup = (e) => {
		e.preventDefault();
		onClickPopup();
	}

  return (
    <div className="uni-popup form" style={display}>
			<div className="uni-popup-close-overlay" onClick={togglePopup}></div>
			<div className="uni-popup-form">
				<div className="uni-h-wrap">
					<h2>Want to learn more about Sunset Health?</h2>
					<p>Drop us a line and one of our friendly representatives will get in touch with details on what Sunset can do for your business.<br/></p>
				</div>
				<HelpForm />
				<a src="#" className="uni-popup-close" onClick={togglePopup} />
			</div>
    </div>
  )
}

export default PopupForm
