import React, { useState } from "react";
import { Link, navigate } from "gatsby"
import axios from 'axios';
import { 
  Formik,
  Form, 
  Field,
  ErrorMessage 
} from 'formik';
import * as yup from 'yup';
import Loader from './loader';
import { rhythm, scale } from "../utils/typography"

const userApiClient = axios.create({
  // baseURL: process.env.GATSBY_BASE_API_URL,
  baseURL: 'https://api.sunset.health/api/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: process.env.TIMEOUT,
})

let regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
let email_regex_validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;
let validation = yup.object().shape({
    first_name: yup
                .string()
                .required('First name is required'),
    last_name: yup
                .string()
                .required('Last name is required'),
    email: yup
            .string()
            .email('Not a valid email')
            .required('Email is required'),
    // employer_name: yup
    //         .string()
    //         .required('Company name is required'),
});

const HelpForm = ({ location, title, fontColor, backgroundColor, children }) => {

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function loadData () {
    setLoading(true);
  }

  async function disableLoader () {
    setLoading(false);
  }


  const rootPath = `${__PATH_PREFIX__}/`
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
      }}
    >
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          employer_name: "",
          email: "",
          help_description: ""
        }}
        validationSchema={validation}
        onSubmit={(values) => {
          loadData();
          setIsSuccessful(false);
          userApiClient
          .post('request_help', { 
              'first_name': values.first_name, 
              'last_name': values.last_name, 
              'employer_name': values.employer_name,
              'email': values.email, 
              'help_description': values.help_description
          })
          .then((response) => {
            process.env.NODE_ENV === 'production' && typeof window !== "undefined" && window.gtag("event", "request", {   
              'event_category': "Submit Request",
              'event_label': "Request submitted",
              'value': 2 
            })
            disableLoader();
            setIsSuccessful(true);
          })
          .catch(error => {
            if (error.response.status === 404) {
              navigate('/404')
              disableLoader();
            } else {
              setSubmitError(true);
              disableLoader();
            }
          })
        }}
    >
        { ({isValid, dirty, resetForm}) => {
          return (
          <Form className="helpform bg-f0f6f9">
              <div className='input-container name-container'>
                <div className='name-wrapper'>
                  <div className='label-wrapper'>
                    <label className='input-label' htmlFor="first_name" style={{ color: fontColor }}>First Name</label>
                  </div>
                  <Field
                    className='form-input request-form-input first-name-input'
                    type='text'
                    placeholder={'Enter your first name'}
                    name='first_name'
                    onKeyDown={() => setSubmitError(false)}
                    style={{ borderColor: fontColor }}
                  />
                  <ErrorMessage className='error' component="div" name='first_name' />
                </div>
                <div className='name-wrapper'>
                  <div className='label-wrapper'>
                    <label className='input-label' htmlFor="last_name" style={{ color: fontColor }}>Last Name</label>
                  </div>
                  <Field
                    className='form-input request-form-input'
                    type='text'
                    placeholder={'Enter your last name'}
                    name='last_name'
                    onKeyDown={() => setSubmitError(false)}
                    style={{ borderColor: fontColor }}
                  />
                  <ErrorMessage className='error' component="div" name='last_name' />
                </div>
              </div>
              <div className='input-container'>
                <div className='label-wrapper'>
                  <label className='input-label' htmlFor="email" style={{ color: fontColor }}>Email Address</label>
                </div>
                <Field
                  className='form-input request-form-input'
                  type='text'
                  placeholder={'Email Address'}
                  name='email'
                  onKeyDown={() => setSubmitError(false)}
                  style={{ borderColor: fontColor }}
                />
                <ErrorMessage className='error' component="div" name='email' />
              </div>
              <div className='input-container'>
                <div className='label-wrapper'>
                  <label className='input-label' htmlFor="employer_name" style={{ color: fontColor }}>Company Name</label>
                </div>
                <Field
                  className='form-input request-form-input'
                  type='text'
                  placeholder={'Enter your company name'}
                  name='employer_name'
                  onKeyDown={() => setSubmitError(false)}
                  style={{ borderColor: fontColor }}
                />
                {/* <ErrorMessage className='error' component="div" name='employer_name' /> */}
              </div>
              <div className='input-container'>
                <div className='label-wrapper'>
                  <label className='input-label' htmlFor="help_description" style={{ color: fontColor }}>What can we help you with?</label>
                </div>
                <Field
                  className='form-textarea'
                  component='textarea'
                  name='help_description'
                  rows={5}
                  onKeyDown={() => setSubmitError(false)}
                  style={{ borderColor: fontColor }}
                />
              </div>
              {
                submitError && (
                  // <div className='error-container'>
                    <p className='submit-error'>
                      Unable to submit help form. Please try again.
                    </p>
                  // </div>
                )
              }
            <div className='button_container'>
              {
                loading && <Loader /> 
              }
              {
                isSuccessful && (
                  <p className="success-message">Thanks for your request. You'll hear from us very soon!</p>
                )
              }
              {
                (!loading && !isSuccessful) && (<button
                  className='form-btn button_signup button_text'
                  type='submit'
                  disabled={!(isValid && dirty)}
                  style={{ backgroundColor: (isValid && dirty) ? fontColor : '#cecece', transition: 'all 0.3s ease 0s' }}
                >
                  SUBMIT
                </button>)
              }
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  )
}

export default HelpForm