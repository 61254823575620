import React, {useEffect, useState} from "react"
import { Link } from "gatsby"

import PopupForm from './popupform'

const Navbar = () => {

	const [openMenu, setOpenMenu] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [widthResize, setWidthResize] = useState(1440);

	useEffect(() => {
		window.addEventListener('resize', () => {setWidthResize(window.innerWidth)});
		setWidthResize(window.innerWidth);
	}, []);

	const handleClick = () => {
		openMenu ? setOpenMenu(false) : setOpenMenu(true);
	}

	const togglePopup = () => {
		if (isPopupOpen) {
		  setIsPopupOpen(false);
		} else {
		  setIsPopupOpen(true);
		}
	  }
	
	  const handlePopupClick = (e) => {
		// e.preventDefault();
		togglePopup();
	  }

	let sideMenuStyle = openMenu ? {
		 transform: "translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
		 transformStyle: "preserve-3d",
		 transition: "transform 0.3s"
	} : {
		transform: "translate3d(100%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
		transformStyle: "preserve-3d",
		transition: "transform 0.3s"
	}

	let dynamicStyle = widthResize < 930 ? sideMenuStyle : null;
	let dynamicSideBarStyle = (widthResize <= 350 && openMenu) ? {padding: "40px 0"} : {padding: "20px 0"};
	let dynamicLogoStyle = (widthResize <= 430 && openMenu) ? {display: 'none'} : {display: 'block'};
	let dynamicMiniLogoStyle = (widthResize >= 350) ? {display: 'none'} : {display: 'block'};
	let dynamicLottieStyle = (widthResize <= 350 && openMenu) 
								? {padding: "5px 0 20px 0", borderBottom: "1px solid #e6e6e6", borderTop: "none", justifyContent: "flex-end"} 
								: {padding: "10px 0 5px 0", borderTop: "1px solid #e6e6e6"};
	let logo = <Link to="/" aria-current="page" className="uni-header-logo-link" style={dynamicLogoStyle}></Link> 

  return (
	  <>
	  	<PopupForm isPopupOpen={isPopupOpen} onClickPopup={handlePopupClick} />
		<div className="uni-header border">
			<div className="uni-container header-container">
				<div className="uni-header-left" style={dynamicSideBarStyle}>
					{logo}
					<nav className="uni-header-nav" style={dynamicStyle}>
						<Link to="/" aria-current="page" className="uni-header-navlink uni-header-mini-logo" style={dynamicMiniLogoStyle}></Link>
						<Link to="/employers" className="uni-header-navlink">Employers</Link>
						<Link to="/program" className="uni-header-navlink">Program</Link>
						{/* <Link to="/pricing" className="uni-header-navlink">Pricing</Link> */}
						<Link to="/about" className="uni-header-navlink">About</Link>
						<a href="https://apps.apple.com/us/app/sunset-health/id1490337966?itsct=apps_box&amp;itscg=30200" 
							className="ios-app-container">
								<img 
									src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1576108800&h=f58936e21f0e752c8202144c8bced4b7" 
									alt="Download on the App Store" 
									className='uni-home-ios-app'
								/>
						</a>
						<a href='https://play.google.com/store/apps/details?id=com.sunsetfrontend&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
								style={{
									display: 'inline-block', 
									overflow: 'hidden', 
								}}
						>
							<img 
								alt='Get it on Google Play' 
								src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
								className="uni-home-android-app"
								/>
						</a>
					</nav>
				</div>
				<div className="uni-header-btns">
					<button className="uni-cta-btn header-cta btn" onClick={handlePopupClick}>Request a Demo</button>
				</div>
				<div className="uni-lottie-burger"
					onClick={handleClick}
					style={dynamicLottieStyle}
				>
					{
						!openMenu ? <img src={require('../../content/assets/icons/menu.svg')} /> 
							: <img src={require('../../content/assets/icons/close.svg')} />
					}
				</div>
			</div>
		</div>
		</>
  )
}

export default Navbar
