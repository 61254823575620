import React from 'react'

const Loader = () => {
    return(
        <>
        <div className="loaderIcon"></div>
        </>
    )
}

export default Loader;